<!-- 短信发送状态报告查询 -->
<template>
  <div>
    <div>
      <div class="tool">
        <div>
          <a-select v-model:value="field" @change="onFieldChange">
            <a-select-option value="uname">账户名称</a-select-option>
          </a-select>
          <a-input-search
            v-model:value="keyword"
            placeholder="请输入关键词"
            enter-button
            @search="onSearch"
            @change.stop="onSearchChange"
          />
        </div>
      </div>
    </div>
    <div style="margin-top: 6px">
      <a-table
        :scroll="{ x: 1000 }"
        :rowKey="(record) => record.id"
        :pagination="false"
        class="ant-table-striped"
        size="middle"
        :loading="loading"
        bordered
        :columns="columns"
        :data-source="sendResultList"
        :rowClassName="
          (record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
      >
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pagination.pageIndex"
          :pageSize="pagination.pageSize"
          :total="pagination.total"
          :page-size-options="pagination.pageSizeOptions"
          :showTotal="(total) => `共 ${total} 条记录`"
          show-size-changer
          @change="onChangePage"
          @showSizeChange="onShowSizeChange"
        >
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, defineComponent } from "vue";
import moment from "moment";
import { getSendResultListPage } from "@/api/message/send";
// import SearchArea from "./components/mySearchArea.vue";
export default defineComponent({
  name: "SendResult",
  components: {
    // SearchArea,
  },
  setup() {
    const state = reactive({
      userinfo: JSON.parse(sessionStorage.getItem("userinfo")),
      field: "uname",
      keyword: "",
      searchParam: {
        searchField: "",
        searchValue: "",
        pageIndex: 1,
        pageSize: 10,
        rid: JSON.parse(sessionStorage.getItem("userinfo")).roles[0].id,
      },
      sendResultList: [],
      pagination: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 10, // 默认每页显示数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        total: 0, //总条数
      },
    });
    const columns = [
      {
        title: "账户名称",
        dataIndex: "accountInfo.uname",
        key: "accountName",
        fixed: "left",
        width: 150,
      },
      {
        title: "状态代码",
        dataIndex: "statusCode",
        key: "statusCode",
        ellipsis: true,
        width: 150,
      },
      {
        title: "状态描述",
        dataIndex: "description",
        key: "description",
        ellipsis: true,
        width: 200,
      },
      {
        title: "任务ID",
        dataIndex: "taskId",
        key: "taskId",
        width: 150,
      },
      {
        title: "成功数量",
        dataIndex: "successCounts",
        key: "successCounts",
        width: 150,
      },
      {
        title: "账户余额",
        dataIndex: "amount",
        key: "amount",
        width: 150,
      },
      {
        title: "错误描述",
        dataIndex: "errors",
        key: "errors",
        width: 150,
      },
      {
        title: "发送时间",
        dataIndex: "createDate",
        align: "center",
        key: "createDate",
        width: 200,
        customRender: ({ text }) => {
          return text ? moment(text).format("YYYY-MM-DD HH:ss:mm") : "";
        },
      },
      // {
      //   title: "操作",
      //   align: "center",
      //   key: "action",
      //   width: 300,
      //   fixed: "right",
      //   slots: {
      //     customRender: "action",
      //   },
      // },
    ];
    onMounted(() => {
      if (state.searchValue) {
        state.searchParam.searchField = state.field;
        state.searchParam.searchValue = state.keyword;
        state.searchParam.rid = state.userinfo.roles[0].id;
      }
      initSendResultList(state.searchParam);
    });
    const initSendResultList = (searchParam) => {
      state.loading = true;
      getSendResultListPage(searchParam).then((resp) => {
        if (resp) {
          state.sendResultList = resp.obj.list;
          state.pagination.total = resp.obj.pagination.total;
          state.loading = false;
        }
      });
    };
    const onSearch = () => {
      state.searchParam = {
        searchField: state.field,
        searchValue: state.keyword,
        pageIndex: 1,
        pageSize: 10,
        rid: state.userinfo.roles[0].id,
      };
      initSendResultList(state.searchParam);
    };
    const onSearchChange = () => {
      state.searchParam.searchValue = state.keyword;
      state.searchParam.searchField = state.field;
    };
    const onFieldChange = (value) => {
      state.field = value;
      state.searchParam.searchField = state.field;
    };
    const onChangePage = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchField = state.field;
        state.searchParam.searchValue = state.keyword;
      }
      state.searchParam.rid = state.userinfo.roles[0].id;
      state.pagination.pageIndex = page;
      state.searchParam.pageIndex = page;
      state.searchParam.pageSize = pageSize;
      initSendResultList(state.searchParam);
    };
    const onShowSizeChange = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchField = state.field;
        state.searchParam.searchValue = state.keyword;
      }
      state.searchParam.rid = state.userinfo.roles[0].id;
      state.pagination.pageSize = pageSize;
      state.pagination.pageIndex = 1;
      state.searchParam.pageIndex = 1;
      state.searchParam.pageSize = pageSize;
      initSendResultList(state.searchParam);
    };
    const refData = toRefs(state);
    return {
      ...refData,
      columns,
      initSendResultList,
      onChangePage,
      onShowSizeChange,
      onSearchChange,
      onFieldChange,
      onSearch,
    };
  },
});
</script>
<style lang='scss' scoped>
.tool {
  display: flex;
  justify-content: space-between;
}
.searchAreaCss {
  border: 1px solid #409eff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0px;
}

.ant-input-search {
  width: 300px;
  margin-right: 5px;
}
.pagination {
  margin: 6px 0px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(183, 217, 241, 0.5);
}
</style>